import React, { useEffect, useState } from "react";
import {
  IOfflineTripDetails,
  IDropStatus,
  SCANNER_TYPE,
  Settings,
  USER_TYPE,
  dropPointRegex,
  DropPoint,
} from "pcd_library";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FetchState } from "../lib/http/common";
import DataFetch from "../components/loadup/DataFetch";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusMap, createStatMap, dropIsComplete } from "../lib/tripdetail";
import { TripParcelStatus } from "../lib/types/loadup";
import Scanners from "../components/scanners/scanners";
import ScanSelection from "../components/ScanSelection";
import { ScanStateAction } from "../lib/types/scan";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import UIfx from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type TripSummaryProps = {
  appSettings: Settings;
  userType: USER_TYPE;
  tripFetch: FetchState;
  parcelFetch: FetchState;
  details: IOfflineTripDetails[];
  loadupStatus: TripParcelStatus;
  allScanned: boolean;
  status: IDropStatus[];
  updateStatus: () => void;
  dropScanned: (drop: DropPoint, isUnknown?: boolean) => Promise<any>;
  dropsComplete: boolean;
  scanFx: UIfx;
  errorFx: UIfx;
};

const dropState = (statMap: StatusMap, record: IOfflineTripDetails) => {
  if (statMap[record.dropid]) {
    const status = statMap[record.dropid];

    return status.scannedAt
      ? `${status.scannedParcels}/${status.totalParcels}`
      : record.parcels;
  }

  return record.parcels;
};

const TripSummary: React.FC<TripSummaryProps> = (props) => {
  const {
    details,
    parcelFetch,
    tripFetch,
    allScanned,
    status,
    updateStatus,
    dropsComplete,
    dropScanned,
    loadupStatus,
    appSettings,
    userType,
    scanFx,
    errorFx,
  } = props;

  const history = useHistory();
  const { logger } = useLogger();

  const [chosenDepot, setChosenDepot] = useState<null | IOfflineTripDetails>(
    null
  );
  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);

  useEffect(() => {
    logger.debug("Section: Entered TripSummary");
    return () => logger.debug("Section: Exited TripSummary");
  }, [logger]);

  useEffect(() => {
    logger.debug("TripSummary: details", { details, status, loadupStatus });
  }, [logger, details, status, loadupStatus]);

  useEffect(() => {
    updateStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processLabel = (label?: string) => {
    if (!label) {
      return;
    }

    if (scanState !== ScanStateAction.Scanning) {
      return;
    }

    setScanState(ScanStateAction.Read);

    setCurrentScanError(null);

    try {
      const dropPoint = DropPoint.parse(label);
      logger.debug("TripSummary: Scan the delivery note", {
        label,
        dropPoint,
        chosenDepot,
      });
      if (dropPoint.drop === chosenDepot?.dropid) {
        scanFx.play();
        logger.debug("TripSummary: Drop correct, ready to start");
        dropScanned(dropPoint).then(() =>
          history.push("/deliver/" + dropPoint.drop)
        );
      } else {
        logger.debug("TripSummary: Wrong note scanned", { dropPoint });
        setCurrentScanError("This is not the correct delivery note");
        setScanState(ScanStateAction.Scanning);
      }
    } catch (e) {
      logger.error("TripSummary: Error processing the label", {
        label,
        error: e,
      });
      errorFx.play();
      setCurrentScanError(e.message);
      setScanState(ScanStateAction.Scanning);
    }
  };

  const mapDetails = (details: IOfflineTripDetails[]) => {
    const statMap = createStatMap(status);

    const contents = details.map((record) => {
      // // TODO grab the complete deliveries from list
      const dropComplete = dropIsComplete(statMap, record.dropid);
      // const status = statMap[record.dropid];
      const loadStat = loadupStatus[record.dropid];

      let completion = 0; // 0 - 100%

      if (!allScanned) {
        if (loadStat) {
          completion = Math.round(
            (loadStat.totalScanned / loadStat.totalParcels) * 100
          );
        }
      }

      const style = dropComplete
        ? {}
        : {
            background:
              completion < 100
                ? `linear-gradient(to right, rgb(169 169 169) 0%, rgba(0, 0, 0, 0) ${completion}%)`
                : "rgb(159 242 177 / 35%)",
          };

      return (
        <ListGroup.Item key={`${record.externalId}`} style={style}>
          <Row>
            <Col xs="4">{record.name}</Col>
            <Col xs="5">
              {allScanned ? (
                <Button
                  variant="link"
                  className="px-0"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.preventDefault();
                    setCurrentScanError(null);
                    setChosenDepot(record);
                  }}
                >
                  {record.drop}
                </Button>
              ) : (
                record.drop
              )}
            </Col>
            {dropComplete ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="lg"
              />
            ) : (
              <Col xs="3">
                {allScanned
                  ? dropState(statMap, record)
                  : loadStat
                  ? `${loadStat.totalScanned}/${loadStat.totalParcels}`
                  : record.parcels}
              </Col>
            )}
          </Row>
        </ListGroup.Item>
      );
    });

    return (
      <ListGroup className="mt-2">
        <Row className="mx-1">
          <Col xs="4">
            <strong>Route</strong>
          </Col>
          <Col xs="5">
            <strong>Depot</strong>
          </Col>
          <Col xs="3">
            <strong>Parcels</strong>
          </Col>
        </Row>
        {contents}
      </ListGroup>
    );
  };

  if (
    tripFetch !== FetchState.Received ||
    parcelFetch !== FetchState.Received
  ) {
    return <DataFetch tripFetch={tripFetch} parcelFetch={parcelFetch} />;
  }

  if (chosenDepot) {
    return (
      <>
        <Row className="mt-2">
          <Col xs="12">
            <Button
              variant="link"
              className="pl-0"
              onClick={() => setChosenDepot(null)}
            >
              &#12296; Cancel
            </Button>
          </Col>
        </Row>
        <Alert variant="info">
          Please scan the delivery note for<strong> {chosenDepot.drop} </strong>
          to start dropoff
        </Alert>
        <Row className="justify-content-center">
          {currentScanError && (
            <Col xs="auto">
              <Alert variant="danger">{currentScanError}</Alert>
            </Col>
          )}
        </Row>
        <Row className="justify-content-center">
          <Col xs="12">
            <Scanners
              scanType={scanType}
              facingMode={appSettings.FacingMode}
              onRead={(type, label) => processLabel(label)}
              userType={userType}
              manualPattern={dropPointRegex.source}
              manualExpected="123 A12 1BC or 123"
              manualInstruction="Please enter the large 3 character code found near the top of the label to the left of the address."
              manualPlaceholder="Drop point code"
            />
          </Col>
          <Col xs="auto">
            <ScanSelection
              onChange={(newType) => {
                appSettings.ScanType = newType;
                setScanType(newType);
              }}
              scanner={scanType}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <span>Trip details</span>
      {mapDetails(details)}
      <Row className="mt-2 justify-content-center">
        <Col xs="auto">
          <Link
            to={{
              pathname: "/loadup",
            }}
          >
            <Button disabled={allScanned}>Load Parcels</Button>
          </Link>
          &nbsp;
          <Link
            to={{
              pathname: "/deliver",
            }}
          >
            <Button disabled={!allScanned || dropsComplete}>
              Deliver Parcels
            </Button>
          </Link>
        </Col>
      </Row>
      <br />
      {allScanned && dropsComplete && (
        <Alert variant="success" className="text-center">
          All parcels delivered, trip is complete
        </Alert>
      )}
    </>
  );
};

export default TripSummary;
