import { ITripDetails, ITripParcel, DateFormat, IDropPoint } from "pcd_library";
import moment from "moment";
import { TripParcelPriority } from "pcd_library/dist/lib/RecordStore";

export interface ITrip {
    ID: number;
    stRoute: string;
    stDrop: string;
    lngParcels: number;
    stDropCode: string;
}

export interface IParcel {
    lngTrip: number;
    lngQRCODE: number;
    stRef: string;
    stCon2: string;
    stCon3: string
    stVans: string;
    FPDROP: string;
    FPPRIORITY: string;
}

export interface IDrop {
    lngTrip: number;
    FSDROP: string;
    FSNAME: string;
    FSPOSTCODE: string;
    FSWEBDROP: string;
}

export default class DriverMapper {
    public static mapTripToDexie(trip: ITrip): ITripDetails {
        return {
            externalId: trip.ID,
            name: trip.stRoute,
            drop: trip.stDrop,
            parcels: trip.lngParcels,
            dropid: trip.stDropCode,
        }
    }

    public static mapParcelToDexie(parcel: IParcel): ITripParcel[] {
        const trip = parcel.lngTrip;
        const qrcode = parcel.lngQRCODE;
        const pieces = (parcel.stCon2 || parcel.stRef).split('|');
        const exclusions = (parcel.stCon3 || '').split('|');
        const scans = (parcel.stVans || '').split('|')
        const dropPoint = parcel.FPDROP;
        const priority = parseInt(parcel.FPPRIORITY, 10) > 0 ? TripParcelPriority.HighPriority : TripParcelPriority.Standard;
        
        return pieces
            .filter((piece) => exclusions.indexOf(piece) === -1)
            .map(piece => {
                const sub = parseInt(piece, 10);
                return {
                    trip,
                    qrcode,
                    sub,
                    scannedAt: scans[sub - 1] ? moment(scans[sub - 1], DateFormat.Warehouse).toDate() : undefined,
                    dropPoint,
                    priority,
                }
            });
    }

    public static mapDropToDexie(drop: IDrop): IDropPoint {
        return {
            trip: drop.lngTrip,
            drop: drop.FSDROP,
            name: drop.FSNAME,
            postcode: drop.FSPOSTCODE.toUpperCase(),
            parent: drop.FSWEBDROP,
        }
    }
}