import React from 'react';
import { IUserAuthenitcation } from '../lib/interfaces/IUserAuthentication';
import { Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { USER_TYPE } from 'pcd_library';

export type DefaultActionRouteProps = {
    path: string,
    authentication: IUserAuthenitcation
}

function getDefaultLocation(authentication: IUserAuthenitcation): string {
    let value = '';
    
    switch (authentication.role) {
        case USER_TYPE.Courier: 
            value = '/trip-summary';
            break;
        case USER_TYPE.Driver:
            value = '/trip-summary';
            break;
        case USER_TYPE.Warehouse:
        default:
            value = '/collect';
            break;
    }
    return value;
}

const DefaultActionRoute: React.FC<DefaultActionRouteProps> = (props) => {
    const {path, authentication} = props;

    const newLocation = getDefaultLocation(authentication);

    return <ProtectedRoute path={path} authentication={authentication}
        render={({location}) => <Redirect to={{
            pathname: newLocation,
            state: {
                from: location
            }
        }}/>}
    />
};

export default DefaultActionRoute;