import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOfflineScan, IOfflineTripParcel, ScanState } from "pcd_library";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { useLogger } from "../lib/logger";

export type PendingProps = {
  numberOfPending: number;
  pendingScans: IOfflineScan[];
  tripParcels: IOfflineTripParcel[];
  dropParcels: IOfflineTripParcel[];
  isOnline: boolean;
  requestSend: () => void;
};

const Pending: React.FC<PendingProps> = (props) => {
  const { pendingScans, isOnline, numberOfPending, tripParcels, dropParcels } =
    props;
  const { logger } = useLogger();

  const filterPendingParcels = (
    parcels: IOfflineTripParcel[]
  ): IOfflineTripParcel[] => {
    return parcels.filter((parcel) => parcel.state === ScanState.Pending);
  };

  const pendingTripParcels = useMemo<IOfflineTripParcel[]>(
    () => filterPendingParcels(tripParcels),
    [tripParcels]
  );
  const pendingDropParcels = useMemo<IOfflineTripParcel[]>(
    () => filterPendingParcels(dropParcels),
    [dropParcels]
  );

  const [sendDisabled, setSendDisabled] = useState<boolean>(!isOnline);

  useEffect(() => {
    logger.debug("Section: Entered Pending");
    return () => logger.debug("Section: Exited Pending");
  }, [logger]);

  useEffect(() => {
    logger.debug("Pending: Online status", { isOnline });
    setSendDisabled(!isOnline);
  }, [isOnline, logger]);

  useEffect(() => {
    logger.debug(`Pending: Data`, {
      pendingTripParcels,
      pendingDropParcels,
      numberOfPending,
    });
  }, [logger, pendingTripParcels, pendingDropParcels, numberOfPending]);

  const sendNow = () => {
    setSendDisabled(true);

    setTimeout(() => setSendDisabled(false), 2000);
    logger.debug("Pending: Requesting send of parcels");
    props.requestSend();
  };

  if (numberOfPending === 0) {
    return (
      <Alert variant="success">All scans have been sent to the server</Alert>
    );
  }

  const contentList = (scans: IOfflineScan[]) => {
    const contents = scans.map((record) => (
      <ListGroup.Item key={record.id}>
        <Row>
          <Col xs="5">{record.headerId}</Col>
          <Col xs="5">Parcel {record.sub}</Col>
          <Col xs="2">
            {record.submittedAt ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="lg"
              />
            ) : (
              <FontAwesomeIcon
                className="text-secondary"
                icon="history"
                size="lg"
              />
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  const parcelList = (parcels: IOfflineTripParcel[]) => {
    const contents = parcels.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.qrcode}`}>
        <Row>
          <Col xs="5">{record.qrcode}</Col>
          <Col xs="5">Parcel {record.sub}</Col>
          <Col xs="2">
            {record.submittedAt ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="lg"
              />
            ) : (
              <FontAwesomeIcon
                className="text-secondary"
                icon="history"
                size="lg"
              />
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  return (
    <>
      {!isOnline && (
        <Alert variant="warning">
          You are not currently connected to the internet
        </Alert>
      )}
      <Row>
        <Col xs="8">
          <span>
            Currently {numberOfPending} {numberOfPending > 1 ? "scans" : "scan"}{" "}
            pending upload{" "}
            {isOnline && <Spinner animation="border" size="sm" />}
          </span>
        </Col>
        <Col>
          <Button onClick={sendNow} disabled={sendDisabled}>
            Send Now
          </Button>
        </Col>
      </Row>

      {contentList(pendingScans)}
      {pendingTripParcels.length > 0 && <h4>Load up parcels</h4>}
      {parcelList(pendingTripParcels)}
      {pendingDropParcels.length > 0 && <h4>Delivery parcels</h4>}
      {parcelList(pendingDropParcels)}
    </>
  );
};

export default Pending;
