import React, { useState, useEffect } from "react";
import { FetchState } from "../lib/http/common";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scanners from "../components/scanners/scanners";
import {
  SCANNER_TYPE,
  Code,
  USER_TYPE,
  IOfflineTripParcel,
  ScanState,
  regexpLabel,
} from "pcd_library";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import ScanSelection from "../components/ScanSelection";
import DateFormatter from "../lib/dateFormatter";
import Button from "react-bootstrap/Button";
import UIfx from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type PickupProps = {
  tripFetch: FetchState;
  parcelFetch: FetchState;
  isOnline: boolean;
  appSettings: any;
  userType: USER_TYPE;
  parcels: IOfflineTripParcel[];
  parcelScanned: (parcel: IOfflineTripParcel) => void;
  scanFx: UIfx;
  errorFx: UIfx;
};

enum ScanStateAction {
  Scanning,
  Read,
  Processing,
  Complete,
}

const Pickup: React.FC<PickupProps> = (props) => {
  const { tripFetch, parcelFetch, isOnline, appSettings, parcels } = props;
  const { logger } = useLogger();

  const [scanType, setScanType] = useState<SCANNER_TYPE>(SCANNER_TYPE.QrCode);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [showScanned, setShowScanned] = useState<boolean>(true);

  const [shownItems, setShownItems] = useState<IOfflineTripParcel[]>(parcels);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );
  const [everythingScanned, setEverythingScanned] = useState<boolean>(false);

  useEffect(() => {
    logger.debug("Section: Entered Pickup");
    return () => logger.debug("Section: Exited Pickup");
  }, [logger]);

  useEffect(() => {
    let allScanned = true;

    setShownItems(
      showScanned
        ? parcels
        : parcels.filter((parcel) => {
            if (!parcel.scannedAt) {
              allScanned = false;
            }
            return !parcel.scannedAt;
          })
    );

    if (showScanned) {
      // Check 'some' of the elements for the first that does not have a scannedAt
      allScanned = !parcels.some((parcel) => !parcel.scannedAt);
    }

    setEverythingScanned(allScanned);
  }, [parcels, showScanned]);

  useEffect(() => {
    if (scanState === ScanStateAction.Complete) {
      setTimeout(() => {
        setScanState(ScanStateAction.Scanning);
      }, 500);
    }
  }, [scanState]);

  const processLabel = (label?: string) => {
    if (scanState !== ScanStateAction.Scanning) {
      return;
    }

    if (!label) {
      return;
    }

    setScanState(ScanStateAction.Read);

    setCurrentScanError(null);

    try {
      const parcel = Code.parseLabel(label);

      const found = parcels.find(
        (fparcel) =>
          fparcel.qrcode === parcel.headerId && fparcel.sub === parcel.sub
      );

      if (!found) {
        logger.warn("Pickup: unexpected parcel", { parcel });
        props.errorFx.play();
        setCurrentScanError(
          "This parcel is not expected, are you sure it is for this trip?"
        );
        setScanState(ScanStateAction.Scanning);
      } else if (!found.scannedAt) {
        props.scanFx.play();
        // report scan to app
        props.parcelScanned(found);
        setTimeout(() => {
          setScanState(ScanStateAction.Complete);
        }, 250);
      } else {
        props.errorFx.play();
        setCurrentScanError("Already scanned at " + found.scannedAt);
        setScanState(ScanStateAction.Scanning);
      }
    } catch (e) {
      props.errorFx.play();
      setScanState(ScanStateAction.Scanning);
      setCurrentScanError(e.message);
      logger.error(`Pickup: Failed to parse label: ${e.message}`, {
        name: e.name,
        stack: e.stack,
      });
    }
  };

  if (
    tripFetch !== FetchState.Received ||
    parcelFetch !== FetchState.Received
  ) {
    return (
      <Alert variant="info">
        <p>Fetching your trip and parcel information:</p>
        <Row>
          <Col xs="2">
            {tripFetch === FetchState.Received ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="sm"
              />
            ) : (
              <Spinner size="sm" animation="border" />
            )}
          </Col>
          <Col xs="8">Trip details</Col>
        </Row>
        <Row>
          <Col xs="2">
            {parcelFetch === FetchState.Received ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="sm"
              />
            ) : (
              <Spinner size="sm" animation="border" />
            )}
          </Col>
          <Col xs="8">Parcel details</Col>
        </Row>
      </Alert>
    );
  }

  if (everythingScanned) {
    return (
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <p>All items have been scanned</p>
        </Col>
      </Row>
    );
  }

  const parcelIcon = (parcel: IOfflineTripParcel) => {
    let result;

    if (!parcel.scannedAt) {
      result = <FontAwesomeIcon icon={["far", "square"]} size="lg" />;
    } else if (parcel.scannedAt && !parcel.submittedAt) {
      result = (
        <FontAwesomeIcon
          className="text-warning"
          icon="check-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Failed) {
      result = (
        <FontAwesomeIcon
          className="text-danger"
          icon="times-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Sent) {
      result = (
        <FontAwesomeIcon
          className="text-success"
          icon="check-square"
          size="lg"
        />
      );
    }

    return result;
  };

  const listOutParcels = (parcels: IOfflineTripParcel[]) => {
    const contents = parcels.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.qrcode}`}>
        <Row>
          <Col xs="5">
            Parcel {record.qrcode}-{record.sub}
          </Col>
          <Col xs="5">
            {record.scannedAt
              ? `Scanned in (${DateFormatter.humanReadable(record.scannedAt)})`
              : "Not Scanned"}
          </Col>
          <Col xs="2">{parcelIcon(record)}</Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  return (
    <>
      {!isOnline && (
        <Alert variant="warning">
          You are not currently connected to the internet
        </Alert>
      )}
      <Alert variant="info">Please scan all parcels listed</Alert>
      <Row className="justify-content-center">
        {currentScanError && (
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        )}
        {scanState !== ScanStateAction.Scanning && (
          <Col xs="auto">
            <Alert
              variant={
                scanState === ScanStateAction.Complete ? "success" : "warning"
              }
            >
              {scanState !== ScanStateAction.Complete && (
                <>
                  <Spinner size="sm" animation="border" />
                  <span>Checking label</span>
                </>
              )}
              {scanState === ScanStateAction.Complete && (
                <>
                  <FontAwesomeIcon
                    className="text-success"
                    icon="check-square"
                    size="lg"
                  />
                  <span>Label scanned</span>
                </>
              )}
            </Alert>
          </Col>
        )}
        <Col xs="12">
          <Scanners
            scanType={scanType}
            facingMode={appSettings.FacingMode}
            onRead={(type, label) => processLabel(label)}
            userType={props.userType}
            manualPattern={regexpLabel.source}
          />
        </Col>
        <Col xs="auto">
          <ScanSelection
            onChange={(newType) => setScanType(newType)}
            scanner={scanType}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form>
            <Form.Check
              label="Show scanned items"
              checked={showScanned}
              onChange={() => setShowScanned(!showScanned)}
              id="show-processed-items-loadup"
            />
          </Form>
          <Button
            onClick={() => {
              let lastPromise = Promise.resolve();
              parcels.forEach((parcel) => {
                lastPromise = lastPromise.finally(() => {
                  return new Promise((resolve) => {
                    props.parcelScanned(parcel);
                    setTimeout(resolve, 500);
                  });
                });
              });
            }}
          >
            Scan all
          </Button>
        </Col>
        <Col xs="12">{listOutParcels(shownItems)}</Col>
      </Row>
    </>
  );
};

export default Pickup;
