import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { IScan } from 'pcd_library';

export type ConnectionAlertProps = {
    isConnected: boolean;
    pendingScans: IScan[];
}

const ConnectionAlert: React.FC<ConnectionAlertProps> = (props) => {    

    if (props.isConnected) {
        return <></>;
    }

    return (
        <Alert
            className="connection-alert"
            variant="warning">
                You are currently offline, parcels scanned will be queued until you are back online.
    {props.pendingScans.length > 0 && <span>Pending: {props.pendingScans.length}</span>}
        </Alert>);
}

export default ConnectionAlert;