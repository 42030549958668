import React, { useState, useEffect } from "react";
import {
  Settings,
  USER_TYPE,
  SCANNER_TYPE,
  regexpLabel,
  IOfflineTripParcel,
  Code,
} from "pcd_library";
import UIfx from "../lib/uifx";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelCheckAlert from "../components/LabelCheckAlert";
import { ScanStateAction } from "../lib/types/scan";
import Scanners from "../components/scanners/scanners";
import ScanSelection from "../components/ScanSelection";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FetchState } from "../lib/http/common";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TripParcelPriority } from "pcd_library/dist/lib/RecordStore";
import { useLogger } from "../lib/logger";

export type LeaveParcelsProps = {
  appSettings: Settings;
  userType: USER_TYPE;
  parcels: IOfflineTripParcel[];
  scanFx: UIfx;
  errorFx: UIfx;
  allScanned: boolean;
  leaveParcels: (
    parcels: IOfflineTripParcel[],
    reason: string
  ) => Promise<boolean>;
};

export enum LeaveStages {
  Scan,
  Reason,
  Submit,
}

const LeaveParcels: React.FC<LeaveParcelsProps> = ({
  appSettings,
  userType,
  parcels,
  scanFx,
  errorFx,
  leaveParcels,
}) => {
  const { logger } = useLogger();
  const [stage, setStage] = useState<LeaveStages>(LeaveStages.Scan);
  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );

  const [parcelsToLeave, setParcelsToLeave] = useState<IOfflineTripParcel[]>(
    []
  );

  const [validatedReason, setValidatedReason] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const [submitState, setSubmitState] = useState<FetchState>(FetchState.Init);

  useEffect(() => {
    logger.debug("Section: Entered LeaveParcels");
    return () => logger.debug("Section: Exited LeaveParcels");
  }, [logger]);

  useEffect(() => {
    if (scanState === ScanStateAction.Complete) {
      setTimeout(() => {
        setScanState(ScanStateAction.Scanning);
      }, 500);
    }
  }, [scanState]);

  const processLabel = (label?: string) => {
    if (scanState !== ScanStateAction.Scanning) {
      return;
    }

    if (!label) {
      return;
    }

    setScanState(ScanStateAction.Read);

    setCurrentScanError(null);

    try {
      const parcel = Code.parseLabel(label);

      const found = parcels.find(
        (fparcel) =>
          fparcel.qrcode === parcel.headerId && fparcel.sub === parcel.sub
      );

      if (!found) {
        setCurrentScanError("This parcel is not expected for this trip");
        setScanState(ScanStateAction.Scanning);
      } else {
        scanFx.play();

        const alreadyAdded = parcelsToLeave.find(
          (lparcel) => lparcel.id === found.id
        );

        if (!alreadyAdded) {
          setParcelsToLeave(parcelsToLeave.concat([found]));
        }

        setTimeout(() => {
          setScanState(ScanStateAction.Complete);
        }, 250);
        return;
      }
    } catch (e) {
      setCurrentScanError(e.message);
    }

    errorFx.play();
    setTimeout(() => {
      setScanState(ScanStateAction.Scanning);
    }, 500);
  };

  const undoParcel = (qrcode: number, sub: number) => {
    setParcelsToLeave(
      parcelsToLeave.filter(
        (parcel) => !(parcel.qrcode === qrcode && parcel.sub === sub)
      )
    );
  };

  const listOutParcels = (parcels: IOfflineTripParcel[]) => {
    const contents = parcels.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.qrcode}`}>
        <Row>
          <Col xs="8">
            Parcel {record.qrcode}-{record.sub}
          </Col>
          <Col xs="4">
            <Button
              variant="outline-secondary"
              onClick={() => undoParcel(record.qrcode, record.sub)}
            >
              Undo
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  const handleSubmitReason = (ev: React.FormEvent<HTMLFormElement>) => {
    const form = ev.currentTarget;
    ev.preventDefault();
    ev.stopPropagation();

    if (form.checkValidity() === false) {
      setValidatedReason(true);
    }
  };

  const handleSubmitLeave = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    setSubmitState(FetchState.Request);

    leaveParcels(parcelsToLeave, reason)
      .then(() => {
        setSubmitState(FetchState.Received);
      })
      .catch(() => {
        setSubmitState(FetchState.Failed);
      });
  };

  const parcelsToLeaveCount = parcelsToLeave.length;
  let priorityCount = 0;
  if (parcelsToLeaveCount > 0) {
    parcelsToLeave.forEach((parcel) => {
      if (parcel.priority > TripParcelPriority.Standard) {
        ++priorityCount;
      }
    });
  }

  const stParcel = parcelsToLeaveCount === 1 ? "Parcel" : "Parcels";
  const countShown = parcelsToLeaveCount === 1 ? "a" : parcelsToLeaveCount;
  const priorityCountShown = priorityCount === 1 ? "a" : priorityCount;
  const stPriorityParcel = priorityCount === 1 ? "Parcel" : "Parcels";

  if (stage === LeaveStages.Reason) {
    let reasonMessage = "";

    if (parcelsToLeaveCount === priorityCount) {
      reasonMessage = `You have selected ${priorityCountShown} Priority ${stParcel} to be removed from this trip, please provide a reason`;
    } else {
      const priorityMessage =
        priorityCount > 0
          ? ` (including ${priorityCountShown} Priority ${stPriorityParcel})`
          : "";
      reasonMessage = `You have selected ${countShown} ${stParcel}${priorityMessage} to be removed from this trip, please provide a reason`;
    }

    return (
      <>
        <Alert variant="info">{reasonMessage}</Alert>
        <Row>
          <Col xs="12">
            <Form
              noValidate
              validated={validatedReason}
              onSubmit={handleSubmitReason}
            >
              <Form.Group controlId="parcel-leave-reason">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  maxLength="200"
                  required
                  value={reason}
                  onChange={(ev: React.FormEvent<HTMLTextAreaElement>) => {
                    setReason(ev.currentTarget.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs="12" className="justify-content-left">
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={() => setStage(LeaveStages.Scan)}
              >
                Back
              </Button>
              <Button
                variant="primary"
                disabled={reason.length < 5}
                onClick={() => setStage(LeaveStages.Submit)}
              >
                Next
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }

  if (stage === LeaveStages.Submit && submitState !== FetchState.Received) {
    let submitMessage = "";

    if (parcelsToLeaveCount === priorityCount) {
      submitMessage = `${priorityCount} Priority ${stPriorityParcel} removed from this trip, please check the details are correct then click submit`;
    } else {
      const prioritySubmitMessage =
        priorityCount > 0 ? ` (${priorityCount} Priority)` : "";
      submitMessage = `${parcelsToLeaveCount} ${stParcel}${prioritySubmitMessage} removed from this trip, please check the details are correct then click submit`;
    }

    return (
      <>
        <Alert variant="info">{submitMessage}</Alert>
        {submitState === FetchState.Request && (
          <Alert variant="warning">
            <Spinner size="sm" animation="border" />
            <span>Updating parcels</span>
          </Alert>
        )}
        {submitState === FetchState.Failed && (
          <Alert variant="danger">
            An error occurred updating the parcels, please try again
          </Alert>
        )}
        <Row>
          <Form onSubmit={handleSubmitLeave}>
            <Col xs="12">
              <Form.Group controlId="parcel-leave-reason-review">
                <Form.Label>Number of Parcels left</Form.Label>
                <Form.Control plaintext defaultValue={parcelsToLeave.length} />
              </Form.Group>
              <Form.Group controlId="parcel-leave-reason-review">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  plaintext
                  defaultValue={reason}
                />
              </Form.Group>
            </Col>
            <Col xs="12" className="justify-content-left">
              <ButtonGroup>
                <Button
                  variant="secondary"
                  disabled={submitState === FetchState.Request}
                  onClick={() => setStage(LeaveStages.Reason)}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    submitState === FetchState.Request || reason.length < 5
                  }
                  type="submit"
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Col>
          </Form>
        </Row>
      </>
    );
  }

  if (stage === LeaveStages.Submit && submitState === FetchState.Received) {
    return (
      <>
        <Alert variant="success">
          <FontAwesomeIcon
            className="text-success"
            icon="check-square"
            size="lg"
          />
          <span>Update complete</span>
        </Alert>
        <Link
          to={{
            pathname: "/trip-summary",
          }}
        >
          <Button variant="primary">Trip Summary</Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <Alert variant="info">
        Please scan all parcels that are being left then click Next to proceed
      </Alert>
      <Row className="justify-content-center">
        {currentScanError && (
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        )}

        {scanState !== ScanStateAction.Scanning && (
          <Col xs="auto">
            <LabelCheckAlert
              isWorking={scanState !== ScanStateAction.Complete}
            />
          </Col>
        )}
        <Col xs="12">
          <Scanners
            scanType={scanType}
            facingMode={appSettings.FacingMode}
            onRead={(type, label) => processLabel(label)}
            userType={userType}
            manualPattern={regexpLabel.source}
          />
        </Col>
        <Col xs="auto">
          <ScanSelection
            onChange={(newType) => setScanType(newType)}
            scanner={scanType}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="12">
          <span>Click next when all parcels are scanned </span>
          <Button
            disabled={parcelsToLeave.length === 0}
            variant="primary"
            onClick={() => setStage(LeaveStages.Reason)}
          >
            Next
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">{listOutParcels(parcelsToLeave)}</Col>
      </Row>
    </>
  );
};

export default LeaveParcels;
