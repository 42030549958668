import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  RecordStore,
  instance,
  ServiceWorkerActions,
  ServiceWorkerSync,
} from "pcd_library";
import { authenticateServiceWorkerActions } from "./lib/http/user";
import Authentication from "./lib/authentication";
import { LoggerProvider } from "./lib/logger";

const myRecordStore = new RecordStore();

const initUserAuth = Authentication.retrieveUserAuthentication();

const sendSyncRequest = (sync: string) => {
  navigator.serviceWorker.ready.then((swRegistration) => {
    return swRegistration.sync.register(sync);
  });

  // Only relevant for development
  if (process.env.NODE_ENV !== "production") {
    console.log("Development mock sw send", sync);
    const actions = new ServiceWorkerActions(
      process.env.REACT_APP_BASE_URL_API || ""
    );
    actions.ready.then(() => {
      authenticateServiceWorkerActions(actions)
        .then(() => {
          actions.log(`Running mock function for: ${sync}`);
          switch (sync) {
            case ServiceWorkerSync.SendScans:
              actions.sendAllPendingScans();
              break;
            case ServiceWorkerSync.SendDriverParcels:
              actions.sendAllPendingDriverTripParcels();
              break;
            case ServiceWorkerSync.SendCourierParcels:
              actions.sendAllPendingCourierTripParcels();
              break;
            case ServiceWorkerSync.SendDropParcels:
              actions.sendAllPendingDropParcels();
              break;
          }
        })
        .catch((e) => {
          console.error("Unable to authenticate mock service worker actions");
        });
    });
  } else {
    console.log("Not in development");
  }
};

ReactDOM.render(
  <LoggerProvider>
    <App
      appSettings={instance}
      appStore={myRecordStore}
      requestSync={sendSyncRequest}
      initialAuthentication={initUserAuth}
    />
  </LoggerProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => window.location.reload(),
});
