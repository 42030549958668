import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Scanners from "../components/scanners/scanners";
import ScanSelection from "../components/ScanSelection";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  USER_TYPE,
  IOfflineTripParcel,
  SCANNER_TYPE,
  DropPoint,
  ScanState,
  IOfflineDropPoint,
  dropPointRegex,
  Settings,
} from "pcd_library";
import { ScanStateAction } from "../lib/types/scan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import DateFormatter from "../lib/dateFormatter";
import { useHistory, Link } from "react-router-dom";

import UIfx from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type DeliverProps = {
  appSettings: Settings;
  userType: USER_TYPE;
  dropPoints: IOfflineDropPoint[];
  dropScanned: (drop: DropPoint, isUnknown?: boolean) => Promise<any>;
  scanFx: UIfx;
  errorFx: UIfx;
  allScanned: boolean;
  activeDelivery: string;
};

const Deliver: React.FC<DeliverProps> = ({
  appSettings,
  userType,
  dropPoints,
  dropScanned,
  errorFx,
  scanFx,
  allScanned,
  activeDelivery,
}) => {
  const history = useHistory();
  const { logger } = useLogger();
  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [showScanned, setShowScanned] = useState<boolean>(true);

  const [shownItems, setShownItems] = useState<IOfflineDropPoint[]>([]);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );
  const [queryDropPoint, setQueryDropPoint] = useState<DropPoint | null>(null);

  useEffect(() => {
    logger.debug("Section: Entered Deliver");
    return () => logger.debug("Section: Exited Deliver");
  }, [logger]);

  useEffect(() => {
    // filter out items that are not parent drops and if show Scanned items
    setShownItems(
      dropPoints.filter(
        (point) =>
          point.drop === point.parent && (showScanned || !point.scannedAt)
      )
    );
  }, [showScanned, dropPoints]);

  const processLabel = (label?: string) => {
    if (!label) {
      return;
    }

    if (scanState !== ScanStateAction.Scanning) {
      return;
    }

    setScanState(ScanStateAction.Read);

    setCurrentScanError(null);

    try {
      const dropPoint = DropPoint.parse(label);

      const found = dropPoints.find((drop) => drop.parent === dropPoint.drop);

      if (!found) {
        logger.warn("Scanned an unexpected drop", { label, dropPoint });
        errorFx.play();
        setQueryDropPoint(dropPoint);
        setScanState(ScanStateAction.Scanning);
        return;
      }

      scanFx.play();

      dropScanned(dropPoint).then(() =>
        history.push("/deliver/" + dropPoint.drop)
      );
    } catch (e) {
      errorFx.play();
      setCurrentScanError(e.message);
      setScanState(ScanStateAction.Scanning);
    }
  };

  const makeUnscheduledDrop = () => {
    if (!queryDropPoint) {
      return;
    }

    dropScanned(queryDropPoint, true).then(() =>
      history.push("/deliver/0" + queryDropPoint.drop)
    );
  };

  const listIcon = (parcel: IOfflineTripParcel | IOfflineDropPoint) => {
    let result;

    if (!parcel.scannedAt) {
      result = <FontAwesomeIcon icon={["far", "square"]} size="lg" />;
    } else if (parcel.scannedAt && !parcel.submittedAt) {
      result = (
        <FontAwesomeIcon
          className="text-success"
          icon="check-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Failed) {
      result = (
        <FontAwesomeIcon
          className="text-danger"
          icon="times-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Sent) {
      result = (
        <FontAwesomeIcon
          className="text-success"
          icon="check-square"
          size="lg"
        />
      );
    }

    return result;
  };

  const listOutDropPoints = (points: IOfflineDropPoint[]) => {
    const contents = points.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.drop}`}>
        <Row>
          <Col xs="5">
            {record.name} {record.postcode || record.drop.substr(-3)}
          </Col>
          <Col xs="5">
            {record.scannedAt
              ? `Scanned in (${DateFormatter.humanReadable(record.scannedAt)})`
              : "Not Scanned"}
          </Col>
          <Col xs="2">{listIcon(record)}</Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  if (!allScanned) {
    return (
      <>
        <p>
          Missing items for this trip, can not start delivery without these
          items
        </p>
        <Link
          to={{
            pathname: "/loadup",
          }}
        >
          <Button>Scan items</Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <Alert variant="info">Please scan the drop point to start dropoff</Alert>
      <Row className="justify-content-center">
        {currentScanError && (
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        )}
        {scanState !== ScanStateAction.Scanning && (
          <Col xs="auto">
            <Alert
              variant={
                scanState === ScanStateAction.Complete ? "success" : "warning"
              }
            >
              {scanState !== ScanStateAction.Complete && (
                <>
                  <Spinner size="sm" animation="border" />
                  <span>Checking label</span>
                </>
              )}
              {scanState === ScanStateAction.Complete && (
                <>
                  <FontAwesomeIcon
                    className="text-success"
                    icon="check-square"
                    size="lg"
                  />
                  <span>Label scanned</span>
                </>
              )}
            </Alert>
          </Col>
        )}
        {!queryDropPoint && (
          <>
            <Col xs="12">
              <Scanners
                scanType={scanType}
                facingMode={appSettings.FacingMode}
                onRead={(type, label) => processLabel(label)}
                userType={userType}
                manualPattern={dropPointRegex.source}
                manualExpected="123 A12 1BC or 123"
                manualInstruction="Please enter the large 3 character code found near the top of the label to the left of the address."
                manualPlaceholder="Drop point code"
              />
            </Col>
            <Col xs="auto">
              <ScanSelection
                onChange={(newType) => {
                  appSettings.ScanType = newType;
                  setScanType(newType);
                }}
                scanner={scanType}
              />
            </Col>
          </>
        )}
        {queryDropPoint && (
          <Col xs="12">
            <Alert variant="warning">
              This is not an expected drop point are you sure you wish to make a
              delivery here?
              <div className="d-flex justify-content-end">
                <Button onClick={() => setQueryDropPoint(null)}>No</Button>
                <Button variant="danger" onClick={makeUnscheduledDrop}>
                  Yes
                </Button>
              </div>
            </Alert>
          </Col>
        )}
      </Row>
      {!queryDropPoint && (
        <>
          {activeDelivery !== "" && (
            <Row className="justify-content-center">
              <Col xs="auto" className="mt-3">
                <Link
                  to={{
                    pathname: "/deliver/" + activeDelivery,
                  }}
                >
                  <Button variant="info" size="lg">
                    Resume current delivery
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="12">&nbsp;</Col>
            <Col xs="auto">
              <Form>
                <Form.Check
                  label="Show scanned items"
                  checked={showScanned}
                  onChange={() => setShowScanned(!showScanned)}
                  id="show-processed-items-loadup"
                />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs="12">{listOutDropPoints(shownItems)}</Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Deliver;
